<template>
  <div class="d-flex flex-column fill-height">
    <div id="map" class="map" ref="map">
    </div>
    <div id="popup" class="ol-popup">
      <div id="popup-content"></div>
    </div>
    <div>
      <v-select
        v-model="selected"
        :items="devicesArray"
        item-text="name"
        item-value="id"
        label="Seleccionar unidad"
        single-line
        clearable
        class="pl-1 pr-1"
      ></v-select>
    </div>
    <div>
      <v-simple-table height="300" dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                Unidad
              </th>
              <th class="text-center">
                Longitud
              </th>
              <th class="text-center">
                Latitud
              </th>
              <th class="text-center">
                Velocidad
              </th>
              <th class="text-center">
                <div class="d-flex justify-space-between">
                  <span style="align-self: center">Fecha y Hora</span>
                  <v-btn
                    v-if="itemsTable && itemsTable.length"
                    color="success"
                    @click="toExcel"
                    icon
                  >
                    <v-icon>mdi-file-excel</v-icon>
                  </v-btn>
                </div>
              </th>
            </tr>
          </thead>
            <tbody
              v-hotkey="keymap"
            >
              <tr
                style="cursor: pointer;"
                :class="{ active: false}"
                v-for="(item) in itemsTable"
                :key="item.name"
                @click="selectCoordinate(item)"
                ref="lista"
              >
                <td class="text-center">
                  <v-chip
                    :color="devicesSelected[item.deviceId].color"
                    dark
                    label
                    small
                  >
                    {{ devicesSelected[item.deviceId].name }}
                  </v-chip>
                </td>
                <td class="text-center">{{ item.longitude }}</td>
                <td class="text-center">{{ item.latitude }}</td>
                <td class="text-right">{{ item.speed | speed}}</td>
                <td class="text-center">{{ item.serverTime | datetime }}</td>
              </tr>
            </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'underscore'
import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'
import arrow from '@/assets/map/arrow.svg'
import ExportToExcel from '@/util/json_to_excel.service'
import { periods } from '@/util'
import Telemetrias from './Telemetrias.vue';

const ol = window.ol

export default {
  name: 'RoutesReport',
  props: {
    devices: Array,
    groups: Array,
  },
  data() {
    return {
      map: null,
      initZoom: 12,
      routes: [],
      mapRoutes: {},
      devicesSelected: {},
      selected: null,
      selectedIndex: null,
      mapRouteColors: [
        '#F06292',
        '#BA68C8',
        '#4DD0E1',
        '#4DB6AC',
        '#FF8A65',
        '#A1887F'
      ],
      filtro: {
        from: new Date(),
        fromHour: '00:00',
        to: new Date(),
        toHour: '23:59',
      },
      filtersForm: {
        deviceId: {
          label: 'Unidaes',
          type: Array,
          options: this.devices,
          multiple: true,
          optional: false
        },
        'deviceId.$': {
          type: String,
          blackbox: true,
        },
        // groupId: {
        //   label: 'Grupos',
        //   type: Array,
        //   options: this.groups,
        //   multiple: true,
        //   optional: true
        // },
        // 'groupId.$': {
        //   type: String,
        //   blackbox: true,
        // },
        periodo: {
          label: 'Periodo',
          type: String,
          options: periods,
          onChange: (val, fields) => {
            fields.from_separator.setVisibility(val === 'custom')
            fields.from.setVisibility(val === 'custom')
            fields.fromHour.setVisibility(val === 'custom')
            fields.to_separator.setVisibility(val === 'custom')
            fields.to.setVisibility(val === 'custom')
            fields.toHour.setVisibility(val === 'custom')
          }
        },
        from_separator: {
          label: 'Desde',
          type: String,
          separator: true,
          optional: true
        },
        from: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        fromHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        },
        to_separator: {
          label: 'Hasta',
          type: String,
          separator: true,
          optional: true
        },
        to: {
          label: 'Fecha',
          type: Date,
          visibility: false
        },
        toHour: {
          label: 'Hora (HH:mm)',
          type: String,
          visibility: false,
          time: true
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    itemsTable() {
      return this.selected ? this.routes.filter((route) => route.deviceId === this.selected) : this.routes
    },
    devicesArray() {
      return _.toArray(this.devicesSelected);
    },
    keymap() {
      return {
        up: () => {
          if (this.selectedIndex > 0) {
            this.selectCoordinate(this.itemsTable[this.selectedIndex - 1])
          }
        },
        down: () => {
          if (this.selectedIndex != null && this.selectedIndex + 1 <= this.itemsTable.length) {
            this.selectCoordinate(this.itemsTable[this.selectedIndex + 1])
          }
        }
      }
    }
  },
  mounted() {
    this.map = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
      })
    })
    this.satelliteMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        url: '//mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
      })
    })
    this.satelliteMap.setVisible(false)
    this.darkmodeMap = new ol.layer.Tile({
      source: new ol.source.XYZ({
        // url: '//api.maptiler.com/maps/a881ff38-8ab2-49ee-baae-c8ac0a1ede8f/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
        url: '//api.maptiler.com/maps/6dea3d11-51b5-48e9-997b-ebba1fdfefe4/256/{z}/{x}/{y}.png?key=G3BcWuok730ChWrFiN06'
      })
    })
    this.darkmodeMap.setVisible(this.mapMode === 'night')

    this.mapView = new ol.View({
      center: this.getMapCenter || ol.proj.fromLonLat([
        this.getUser.longitude, this.getUser.latitude
      ]),
      zoom: this.initZoom
    })

    this.routesSource = new ol.source.Vector()
    this.routesLayer = new ol.layer.Vector({
      source: this.routesSource
    });

    this.pointsSource = new ol.source.Vector()
    this.pointsLayer = new ol.layer.Vector({
      source: this.pointsSource
    });

    this.olmap = new ol.Map({
      target: 'map',
      layers: [
        this.map,
        this.darkmodeMap,
        this.satelliteMap,
        this.routesLayer,
        this.pointsLayer
      ],
      view: this.mapView
    })
    this.routesLayer.setVisible(true)

    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const popupOverlay = new ol.Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });

    this.olmap.addOverlay(popupOverlay);
    const TelemetriasConstructor = Vue.extend(Telemetrias);
    const instance = new TelemetriasConstructor({});
    this.olmap.on('pointermove', (e) => {
      if (e.dragging) {
        return;
      }
      const pixel = this.olmap.getEventPixel(e.originalEvent);
      const hit = this.olmap.hasFeatureAtPixel(pixel);
      this.olmap.getTargetElement().style.cursor = hit ? 'pointer' : '';
      if (hit) {
        this.olmap.forEachFeatureAtPixel(e.pixel, (feature) => {
          const record = feature.get('record')
          if (record && this.positionHover !== record.id) {
            this.positionHover = record.id
            popupOverlay.element.hidden = false;
            content.innerHTML = '';
            // Crear una instancia del componente de Vue.js
            instance.device = this.devicesSelected[record.deviceId]
            instance.item = record

            // Montar el componente en el contenido del pop-up
            instance.$mount(content);

            // Establecer la posición del overlay y mostrarlo
            popupOverlay.setPosition(e.coordinate);
          }
        })
      } else {
        popupOverlay.element.hidden = true;
        this.positionHover = null
      }
    });

    this.olmap.on('click', (e) => {
      this.olmap.forEachFeatureAtPixel(e.pixel, (feature) => {
        if (!feature.get('record')) {
          this.selected = parseInt(feature.getId())
        }
      })
    });
  },
  methods: {
    async load() {
      window.VMA.loading(true)
      const data = {
        deviceId: this.filtro.deviceId,
        // groupId: this.filtro.groupId,
        ...this.period
      }
      try {
        this.routes = (await ApiService({
          url: '/reports/route',
          method: 'get',
          params: data
        })).filter((a) => a.latitude || a.longitude)
        if (this.routes.length) {
          this.selectedIndex = 0
          this.selectCoordinate(this.routes[0])
        }
      } catch (e) {
        console.error(e)
        window.VMA.showError({ title: 'Ocurrió un error al cargar los datos' })
      } finally {
        window.VMA.loading(false)
      }
    },
    filtrar() {
      const form = new Form({
        schema: this.filtersForm,
        item: this.filtro
      })
      const dialog = new Dialog({
        title: 'Filtrar rutas',
        actions: [{
          help: 'Filtrar',
          icon: 'mdi-filter',
          color: 'secondary',
          action: async () => {
            if (form.hasErrors()) {
              return
            }
            const item = form.getItem()
            this.filtro = item
            if (item.periodo !== 'custom') {
              this.period = form.fields.periodo.getOption().getPeriod()
            } else {
              this.period = form.fields.periodo.getOption().getPeriod({
                ..._.pick(item, 'from', 'to', 'fromHour', 'toHour')
              })
            }
            this.selected = null
            let colorCounter = 0
            const devices = []
            // if (form.fields.groupId.getOption()) {
            //   form.fields.groupId.getOption().forEach((group) => {
            //     if (form.fields.deviceId.getOptions()) {
            //       form.fields.deviceId.getOptions().forEach(device => {
            //         if (device.groupId === group.id) {
            //           device.color = this.mapRouteColors[colorCounter]
            //           colorCounter = (colorCounter + 1) % this.mapRouteColors.length
            //           devices.push(device)
            //         }
            //       })
            //     }
            //   })
            // }
            if (form.fields.deviceId.getOption()) {
              form.fields.deviceId.getOption().forEach((device) => {
                device.color = this.mapRouteColors[colorCounter]
                colorCounter++
                if (colorCounter === this.mapRouteColors.length) {
                  colorCounter = 0
                }
                devices.push(device)
              })
            }
            this.devicesSelected = devices.toObject('id')
            this.load()
            dialog.close()
          }
        }]
      })
      dialog.open()
      dialog.append(form)
    },
    selectCoordinate(item) {
      if (this.pointsSource.getFeatureById('coordinate_selected')) {
        this.pointsSource.removeFeature(this.pointsSource.getFeatureById('coordinate_selected'))
      }
      const circleFeature = new ol.Feature({
        geometry: new ol.geom.Point(ol.proj.fromLonLat([item.longitude, item.latitude])),
      });
      circleFeature.setId('coordinate_selected')
      circleFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          src: arrow,
          rotation: item.course * (3.14159 / 180),
          color: this.devicesSelected[item.deviceId].color,
          scale: 0.8
        })
      }))
      // this.mapView.setZoom(16)
      this.mapView.setCenter(ol.proj.fromLonLat([item.longitude, item.latitude]))
      this.pointsSource.addFeature(circleFeature)
      this.selectedIndex = this.itemsTable.findIndex((it) => it.id === item.id)
      this.$refs.lista.forEach((element, index) => {
        this.$refs.lista[index].className = ''
        if (index === this.selectedIndex) {
          this.$refs.lista[index].className = 'active'
        }
      });
    },
    getRouteStyle(color) {
      return new ol.style.Style({
        stroke: new ol.style.Stroke({
          color,
          width: 5
        })
      })
    },
    toExcel() {
      ExportToExcel(
        'Rutas de unidades '
          + moment(this.period.from).format('DD-MM-YYYY HH mm') + ' a '
          + moment(this.period.to).format('DD-MM-YYYY HH mm'),
        this.itemsTable.map((item) => ({
          Unidad: this.devicesSelected[item.deviceId].name,
          Longitud: item.longitude,
          Latitud: item.latitude,
          Velocidad: item.speed,
          'Fecha y Hora': moment(item.serverTime).format('DD-MM-YYYY HH:mm')
        }))
      )
    },
  },
  watch: {
    itemsTable(val) {
      this.routesSource.clear()
      this.selectedIndex = null
      const unidades = _.groupBy(val, 'deviceId')
      Object.keys(unidades).forEach((key) => {
        const coordinates = unidades[key].map((item) => ol.proj.fromLonLat([item.longitude, item.latitude]))
        const routeFeature = new ol.Feature({
          geometry: new ol.geom.LineString([])
        })
        routeFeature.setId(key)
        const device = this.devicesSelected[key]
        routeFeature.setStyle(this.getRouteStyle(device.color))
        routeFeature.getGeometry().setCoordinates(coordinates)
        this.routesSource.addFeature(routeFeature)
        // this.routesSource.set(key, routeFeature)
      })
    },
    selected(val) {
      this.pointsSource.clear()
      if (!val) {
        return
      }
      const circles = this.routes.filter((route) => route.deviceId === val).map((item) => {
        const circleFeature = new ol.Feature({
          geometry: new ol.geom.Point(ol.proj.fromLonLat([item.longitude, item.latitude])),
        });
        circleFeature.setStyle(new ol.style.Style({
          image: new ol.style.Icon({
            src: arrow,
            rotation: item.course * (3.14159 / 180),
            color: this.devicesSelected[item.deviceId].color,
            scale: 0.6
          })
        }))
        circleFeature.set('record', item)
        circleFeature.setId(item.id)
        return circleFeature
      })

      this.pointsSource.addFeatures(circles)
    }
  }
}

</script>

<style lang="scss">
  .map {
    height: 100%;
    width: 100%;
  }
  .ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "✖";
  }
</style>
